







































































































import ModuleBindings from '@/includes/logic/Modules/mixins/ModuleBindings'
import { InputSetups } from "@/mixins/input-setups";
import { REFERRAL_FILTER_TYPES } from "@/includes/logic/TypedChipsData";
import ReferralFilterHandlerModuleBuilder
  from "@/includes/logic/Modules/models/modules/filters/ReferralFilterHandlerModule/ReferralFilterHandlerModuleBuilder";

import ModuleConfigSettings from '../../components/ModuleConfigSettings.vue'
import ModuleConfigSettingsWrapper from '../../components/ModuleConfigSettingsWrapper.vue'
import FilterJournal from "../../components/filters/FilterJournal.vue";
import FilterCommonSettings from "../../components/filters/FilterCommonSettings.vue";
import FilterNewUsersSettings from "../../components/filters/FilterNewUsersSettings.vue";
import FilterRemoveEditedSetting from "../../components/filters/FilterRemoveEditedSetting.vue";
import FilterExtraSettings from "../../components/filters/FilterExtraSettings.vue";
import FilterResetPunishSettings from '../../components/filters/FilterResetPunishSettings.vue'
import FilterSettingLayout from "../../components/filters/layout/FilterSettingLayout.vue";

import TypedBlackWhiteListInput from 'piramis-base-components/src/components/Pi/fields/TypedBlackWhiteListInput/TypedBlackWhiteListInput.vue';
import BlackWhiteListInput from 'piramis-base-components/src/components/Pi/fields/BlackWhiteListInput/BlackWhiteListInput.vue';
import SwitchInput from 'piramis-base-components/src/components/Pi/fields/SwitchInput/SwitchInput.vue';

import { Component, Mixins, VModel } from 'vue-property-decorator'

@Component({
  data() {
    return {
      REFERRAL_FILTER_TYPES
    }
  },
  components: {
    FilterSettingLayout,
    FilterExtraSettings,
    FilterRemoveEditedSetting,
    FilterResetPunishSettings,
    FilterNewUsersSettings,
    FilterCommonSettings,
    FilterJournal,
    ModuleConfigSettingsWrapper,
    ModuleConfigSettings,
    SwitchInput,
    TypedBlackWhiteListInput,
    BlackWhiteListInput
  },
})
export default class ReferralFilterHandlerModuleView extends Mixins(ModuleBindings, InputSetups) {
  @VModel() module!: ReferralFilterHandlerModuleBuilder
}
